import styled from 'styled-components';

export const TwoColumnContainer = styled.div.attrs({})`
  display: grid;
  @media (max-width: 459px) {
    grid-template-columns: 100%;
  }
  @media (min-width: 460px) and (max-width: 699px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
  }
`;

export const RequiredField = styled.span.attrs({})`
  color: red;
`;

export const RemoveDriverButtonsContainer = styled.div.attrs({})`
  text-align: right;
  margin-right: 2%;
  margin-top: 10%;
`;
export const CancelButton = styled.button.attrs({})`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
`;

export const NextButton = styled.button.attrs({})`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  :disabled {
    background-color: gray;
  }
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
`;

export const RemoveDriverField = styled.form.attrs({})`
  min-width: 200px;
`;
export const RemoveDriverComponent = styled.div.attrs({})`
  margin-bottom: 12px;
`;
export const RemoveLabel = styled.div.attrs({})`
  display: block;
  margin-left: 22px;
`;
export const RemoveDriverRadioButton = styled.input.attrs({
  type: 'radio',
})`
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 5px;
  float: left;
`;

export const RemoveDriverHeader = styled.h4.attrs({
  id: 'RemoveDriverHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.001rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const PhotoIcon = styled.i.attrs({
  className: 'fa fa-picture-o',
  'aria-hidden': true,
})``;
PhotoIcon.displayName = 'PhotoIcon';

export const RemoveDriverDateInputField = styled.input.attrs({
  type: 'date',
})`
  height: 30px;
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const GarbageIconContainer = styled.div.attrs({})`
  margin-left: 15px;
`;
export const GarbageIcon = styled.i.attrs({
  className: 'fa fa-trash-o',
})`
  cursor: pointer;
  margin-left: -15px;
`;

export const RemoveDriverTextAreaField = styled.textarea.attrs({
  id: 'removeDriverTextAreaField',
})`
  height: 100px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
