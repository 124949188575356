import * as React from 'react';
import PropTypes from 'prop-types';

import { ContactContainers, StyledSpan, StyledLink, StyledDiv } from './styles';

export const Contacts = (props) => {
  const { contacts } = props;

  return (
    <>
      {contacts.map((contact, index) => {
        if (contact.type === 'Phone') {
          return (
            <ContactContainers key={index}>
              <i className="fas fa-phone-square-alt" />
              <StyledSpan>
                {contact.name + ': '}
                <StyledLink href={`tel:${contact.value}`}>{contact.value}</StyledLink>
              </StyledSpan>
            </ContactContainers>
          );
        } else if (contact.type === 'Website') {
          return (
            <ContactContainers key={index}>
              <i className="fas fa-globe-americas" />
              <StyledSpan>
                {contact.name + ': '}
                <StyledLink href={`${contact.value}`}>{contact.value}</StyledLink>
              </StyledSpan>
            </ContactContainers>
          );
        } else if (contact.type === 'Email') {
          return (
            <ContactContainers key={index}>
              <i className="fas fa-envelope" />
              <StyledSpan>
                {contact.name + ': '}
                <StyledLink href={`mailto:${contact.value}`}>{contact.value}</StyledLink>
              </StyledSpan>
            </ContactContainers>
          );
        } else if (contact.type === 'Text') {
          return (
            <ContactContainers key={index}>
              <i className="fas fa-scroll" />
              <StyledSpan>
                {contact.name + ': '}
                <StyledDiv>{contact.value}</StyledDiv>
              </StyledSpan>
            </ContactContainers>
          );
        }
      })}
    </>
  );
};

Contacts.propTypes = {
  contacts: PropTypes.any,
};

export default Contacts;
