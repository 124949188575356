import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ChangePasswordButton,
  ChangePasswordContainer,
  ChangePasswordHeader,
  CheckIcon,
  ConfirmPasswordInput,
  ConfirmPasswordLabel,
  CurrentPasswordInput,
  CurrentPasswordLabel,
  NewPasswordInput,
  NewPasswordLabel,
  PasswordConstraintMessageLabel,
  PasswordInputField,
} from './styles';
import { updatePassword } from './profileAPI';
import { useSnackbar } from 'notistack';
import { getText } from '../../../utils/i18n';
import TooltipInfo from '../../common/tooltip';

export const ChangePassword = props => {
  const { dispatch, userId, i18n, notificationPreferences } = props;
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordConstraints, setPasswordConstraints] = useState(
    props.state.configurationSlice.features.passwordConstraints,
  );
  const { enqueueSnackbar } = useSnackbar();

  const changePassword = () => {
    const payload = {
      oldPassword: currentPassword,
      newPassword: password,
    };
    dispatch(
      updatePassword(
        userId,
        payload,
        enqueueSnackbar,
        setPassword,
        setCurrentPassword,
        setConfirmPassword,
      ),
    );
  };

  const validatePassword = passwordValue => {
    const updatePasswordConstraints = [];
    passwordConstraints.forEach(passwordConstraint => {
      const item = { ...passwordConstraint };
      const constraint = item.constraint;
      const value = item.value;
      switch (constraint) {
        case 'minLength':
          item.valid = passwordValue.length >= value;
          break;
        case 'minNumber':
          item.valid = (passwordValue.match(/\d/g) || []).length >= value;
          break;
        case 'minLowerCase':
          item.valid = (passwordValue.match(/[a-z]/g) || []).length >= value;
          break;
        case 'minUpperCase':
          item.valid = (passwordValue.match(/[A-Z]/g) || []).length >= value;
          break;
        default:
          item.valid = passwordValue.length >= value;
          break;
      }
      updatePasswordConstraints.push(item);
    });
    setPasswordConstraints(updatePasswordConstraints);
  };

  const areRequiredFieldsComplete = () => {
    let completeFlag = true;
    completeFlag = completeFlag && currentPassword.length > 0;
    completeFlag =
      completeFlag &&
      passwordConstraints.filter(constraint => constraint && constraint.valid === true).length ===
        passwordConstraints.length;
    completeFlag = completeFlag && password === confirmPassword;
    return completeFlag;
  };

  return (
    <ChangePasswordContainer notificationPreferences={notificationPreferences}>
      <ChangePasswordHeader notificationPreferences={notificationPreferences}>
        Change Password
      </ChangePasswordHeader>
      <p>
        To change your password, please enter your password then provide and verify your new
        password.
      </p>
      <CurrentPasswordLabel>
        Current Password
        <TooltipInfo title={getText(i18n, 'profile.changePasswordTooltip')} size="large" />
      </CurrentPasswordLabel>
      <CurrentPasswordInput>
        <PasswordInputField
          id="currentPassword"
          aria-label="Current Password"
          onChange={e => setCurrentPassword(e.target.value)}
          value={currentPassword}
        />
        <PasswordConstraintMessageLabel>
          {currentPassword.length > 0 && (
            <div id="currentPasswordConstraint">
              {currentPassword.length > 0 ? (
                <CheckIcon id="PasswordNotEmpty" />
              ) : (
                <i className="fa fa-circle-o" />
              )}{' '}
              Current Password cannot be empty
            </div>
          )}
        </PasswordConstraintMessageLabel>
      </CurrentPasswordInput>
      <NewPasswordLabel>
        New Password
        <TooltipInfo title={getText(i18n, 'profile.newPasswordTooltip')} size="large" />
      </NewPasswordLabel>
      <NewPasswordInput>
        <PasswordInputField
          id="password"
          aria-label="New Password"
          onChange={e => {
            validatePassword(e.target.value);
            setPassword(e.target.value);
          }}
          value={password}
        />
        {password.length > 0 &&
          passwordConstraints &&
          passwordConstraints.map(constraint => (
            <Fragment key={constraint.constraint}>
              <PasswordConstraintMessageLabel id={constraint.constraint + 'Constraint'}>
                {constraint.valid ? (
                  <CheckIcon id={constraint.constraint} />
                ) : (
                  <i id={'circle' + constraint.constraint} className="fa fa-circle-o" />
                )}{' '}
                {constraint.message}
              </PasswordConstraintMessageLabel>
            </Fragment>
          ))}
      </NewPasswordInput>
      <ConfirmPasswordLabel>
        Confirm Password
        <TooltipInfo title={getText(i18n, 'profile.confirmPasswordTooltip')} size="large" />
      </ConfirmPasswordLabel>
      <ConfirmPasswordInput>
        <PasswordInputField
          id="confirmPassword"
          aria-label="Confirm Password"
          onChange={e => setConfirmPassword(e.target.value)}
          value={confirmPassword}
        />
        <PasswordConstraintMessageLabel>
          {confirmPassword.length > 0 && (
            <div id="confirmPasswordConstraint">
              {password === confirmPassword ? (
                <CheckIcon id="PasswordsMustMatch" />
              ) : (
                <i id="circlePasswordsMustMatch" className="fa fa-circle-o" />
              )}{' '}
              Passwords must match
            </div>
          )}
        </PasswordConstraintMessageLabel>
      </ConfirmPasswordInput>
      <ChangePasswordButton
        disabled={!areRequiredFieldsComplete()}
        onClick={() => {
          changePassword();
        }}
      >
        Change Password
      </ChangePasswordButton>
    </ChangePasswordContainer>
  );
};

ChangePassword.propTypes = {
  dispatch: PropTypes.func,
  state: PropTypes.object,
  userId: PropTypes.string,
  i18n: PropTypes.object,
  notificationPreferences: PropTypes.bool,
};
const mapStateToProps = state => ({
  isLoading: state.profileSlice.isLoading,
  userId: state.loginSlice.user.preferred_username,
  state,
  i18n: state.i18nSlice.i18n,
});

export default connect(mapStateToProps)(ChangePassword);
