import styled from 'styled-components';
export const StyledError = styled.div`
  margin: 16px;
  max-width: 570px;
`;

export const ContentContainer = styled.div.attrs({
  id: 'paymentReview',
})`
  max-width: 570px;
`;
export const StyledSuccessMessage = styled.span.attrs({})`
  font-size: 14px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: inline;
  font-weight: 400;
`;
export const AmountDisclaimerContainer = styled.div.attrs({})`
  margin-bottom: 20px;
`;
export const AmountDisclaimerText = styled.label`
  font-weight: 500;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const PaymentContainer = styled.div`
  margin: 25px;
`;
export const PaymentHeader = styled.h1`
  font-size: 20px;
  font-weight: 500;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const StepThreeCards = styled.div`
  display: grid;
  margin-bottom: 24px;
  margin-top: 24px;
  border-radius: 4px;
  @media (max-width: 599px) {
    row-gap: 24px;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    row-gap: 24px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    row-gap: 24px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 31% 31% 31%;
    column-gap: 24px;
  }
`;
export const AmountContainer = styled.div`
  display: grid;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    display: grid;
    background-color: #fafafa;
    align-self: center;
    justify-content: center;
    height: 155px;
    @media (max-width: 599px) {
    }
    @media (min-width: 600px) and (max-width: 979px) {
    }
    @media (min-width: 900px) and (max-width: 1279px) {
    }
    @media (min-width: 1280px) {
      
  display: grid;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    
  display: grid;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    /* grid-template-columns: 32% 32% 32%; */
    /* column-gap: 24px; */
  }
 
  display: grid;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    /* grid-template-columns: 32% 32% 32%; */
    /* column-gap: 24px; */
  }

  }
 
  display: grid;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    
  display: grid;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    /* grid-template-columns: 32% 32% 32%; */
    /* column-gap: 24px; */
  }
 
  display: grid;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    /* grid-template-columns: 32% 32% 32%; */
    /* column-gap: 24px; */
  }

  }

    }
    display: grid;
    background-color: #fafafa;
    align-self: center;
    justify-content: center;
    height: 155px;
    @media (max-width: 599px) {
    }
    @media (min-width: 600px) and (max-width: 979px) {
    }
    @media (min-width: 900px) and (max-width: 1279px) {
    }
    @media (min-width: 1280px) {
      
  display: grid;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    
  display: grid;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    /* grid-template-columns: 32% 32% 32%; */
    /* column-gap: 24px; */
  }
 
  display: grid;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    /* grid-template-columns: 32% 32% 32%; */
    /* column-gap: 24px; */
  }

  }
 
  display: grid;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    
  display: grid;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    /* grid-template-columns: 32% 32% 32%; */
    /* column-gap: 24px; */
  }
 
  display: grid;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    /* grid-template-columns: 32% 32% 32%; */
    /* column-gap: 24px; */
  }

  }

    }
  }
`;
export const AmountHeader = styled.div.attrs({
  id: 'amountToBePaid',
})`
  font-size: 20px;
  color: #28333f;
  margin-left: 8px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  align-self: end;
  text-align: center;
`;
export const AmountDetails = styled.div.attrs({
  id: 'amountDetails',
})`
  font-size: 32px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  color: #28333f;
  display: block;
  text-align: center;
  margin-top: 8px;
`;
export const InstrumentDetails = styled.div.attrs({
  id: 'instrumentDetails',
})`
  font-size: 16px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #28333f;
  display: block;
  margin-top: 8px;
`;
export const InstrumentContainer = styled.div.attrs({
  id: 'instrumentContainer',
})`
  display: grid;
  font-size: 16px;
  font-weight: 500;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
`;

export const InstrumentHeader = styled.div.attrs({
  id: 'instrumentHeader',
})`
  font-size: 16px;
  align-self: end;
  text-align: center;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const PayDateContainer = styled.div.attrs({
  id: 'payDateContainer',
})`
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  display: grid;
  height: 155px;
`;
export const DateOfPayment = styled.div.attrs({
  id: 'dateofpayment',
})`
  text-align: center;
  margin-top: 8px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const DateHeader = styled.div.attrs({
  id: 'dateheader',
})`
  font-size: 16px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  align-self: end;
`;
export const StyledCreditIcon = styled.i.attrs({
  className: 'fa fa-lg fa-credit-card',
})`
  padding-right: 5px;
  padding-right: 5px; 
  padding-right: 5px; 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */
 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

 
  padding-right: 5px; 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */
 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */


  padding-right: 5px; 
  padding-right: 5px; 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */
 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

 
  padding-right: 5px; 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */
 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */


`;
export const StyledACHIcon = styled.i.attrs({
  className: 'fa fa-lg fa-university',
})`
  padding-right: 5px;
  padding-right: 5px; 
  padding-right: 5px; 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */
 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

 
  padding-right: 5px; 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */
 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */


  padding-right: 5px; 
  padding-right: 5px; 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */
 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

 
  padding-right: 5px; 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */
 
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */


`;
export const StyledInfoIcon = styled.i.attrs({
  className: 'fa fa-lg fa-info-circle',
})`
  margin-right: 6px;
`;
export const AuthorizationMessage = styled.div`
  margin-top: 40px;
`;
export const NotificationContainer = styled.div`
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  margin-top: 24px;
  padding: 20px 20px 20px 8px;
  border-radius: 4px;
`;
export const StyledInformationMessage = styled.div.attrs({})`
  margin-left: 12px;
  font-size: 14px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: inline-block;
`;
const StyledButtonNext = styled.button.attrs({
  id: 'nextButton',
})`
  margin-left: 10px;
  padding: 8px 47px 8px 47px;
  border: 0;
  transition: 220ms all ease-in-out;
  background-color: ${(props) => props.theme.primaryColor};

  color: white;
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
  /* border-radius: 4px; */

  a:webkit-any-link {
    color: white;
  }
  :disabled {
    background-color: gray;
  }
`;
export const PayFailedButtonHome = styled(StyledButtonNext)`
  margin-left: 0;
  margin-top: 25px;
  padding: 15px 25px 15px 25px;
`;
export const InstructionsContainer = styled.div.attrs({
  id: 'instructionsContainer',
})`
  margin: 25px;
`;
