import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  FileName,
  FileThumbnail,
  FileThumbnailContainer,
  GarbageIcon,
  GarbageIconContainer,
  ImgThumbnail,
  NewPropertyCoverageButton,
  NewPropertyCoverageButtonsContainer,
  NewPropertyCoverageContainer,
  NewPropertyCoverageField,
  NewPropertyCoverageFieldHeader,
  NewPropertyCoverageFileUploadButton,
  NewPropertyCoverageTextAreaField,
  PicturesContainer,
  PitcureIcon,
  RequiredField,
  ThumbnailContainer,
} from './styles';
import moment from 'moment';
import { checkFileSize } from '../../../../../utils/file-utils';
import Loader from '../../../../common/loader';
import { setPolicyChangeCoverageRecapFields } from '../../policyChangeReducer';
import { createField, formatRecapFieldDate } from '../selector';
import { submitPolicyChange, uploadFilesAndSubmitPolicyChange } from '../../policyChangeAPI';
import { useSnackbar } from 'notistack';
import { ISO_DATE_FORMAT } from '../../../../common/constants';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import { useHistory } from 'react-router';

const DEFAULT_NEW_COVERAGE_TEXT =
  'Please Describe The Items You Want to Cover In The Table Below: \n ITEM DESCRIPTION        VALUE \n ------------------------        --------------- \n <Put Item Desc Here>    <Put Value Here>';

const useFallbackThumbnail = file =>
  file.type.includes('document') || file.type.includes('pdf') || file.type.includes('text');

export const RequestNewPropertyCoverage = props => {
  const { selectedPolicyChangeTemplate, uploadFileSize, customerId, selectedPolicy, i18n } = props;
  const { enqueueSnackbar } = useSnackbar();
  const originalDescription = selectedPolicyChangeTemplate.description || DEFAULT_NEW_COVERAGE_TEXT;
  const [description, setDescription] = useState(originalDescription);
  const todayDt = moment().format(ISO_DATE_FORMAT);
  const [effectiveDate, setEffectiveDate] = useState(todayDt);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileSelection = () => {
    const fileInput = document.getElementById('uploadNewPropertyFile');
    let { files } = fileInput;
    files = [...files];

    const goodFiles = checkFileSize(files, uploadFileSize, enqueueSnackbar);
    // 500000000
    [...goodFiles].forEach(file => {
      const reader = new FileReader();
      reader.onloadend = () => {
        file.url = reader.result;
        // setUploadFiles doesn't update the state immediately unless we follow the following technique
        setUploadFiles(oldList => [...oldList, file]);
      };
      reader.readAsDataURL(file);
    });
  };

  const checkRequiredFields = () => {
    return originalDescription !== description;
  };

  const onSubmitButtonClicked = () => {
    setIsSubmitting(true);

    // create payload
    const payload = {
      changeDt: effectiveDate,
      templateIdRef: selectedPolicyChangeTemplate.id,
      customerRef: customerId,
      policyDetail: {
        policyRef: selectedPolicy.value,
      },
      changeRequest: [
        {
          detailDescription: description,
        },
      ],
      sourceCd: 'ServicePortal',
    };

    const createPolicyChangeCoverageSummary = () => {
      const fields = [];
      fields.push(
        createField('Change Requested', selectedPolicyChangeTemplate.name, 'text', false),
      );
      fields.push(
        createField('Requested Effective Date', formatRecapFieldDate(effectiveDate), 'text'),
      );
      fields.push(createField('Description', description, 'textArea'));
      fields.push(
        createField(
          'Pictures of the items',
          uploadFiles.length > 0 ? 'Included' : 'Not included',
          'text',
        ),
      );
      return fields;
    };

    // set fields for recap/result
    props.dispatch(setPolicyChangeCoverageRecapFields(createPolicyChangeCoverageSummary()));

    // submit API call
    if (uploadFiles.length > 0) {
      // upload attachments and call submit policy change
      props.dispatch(uploadFilesAndSubmitPolicyChange(uploadFiles, payload));
    } else {
      // No attachments, update payload and call submitPolicyChange
      const policyChangeSection = {
        id: 'PolicyChangeSection001',
        name: 'Item Photos',
        sectionCd: 'ItemPhotos',
        policyChangeAttachment: [
          {
            id: 'PropertyAttachment',
            description: 'Pictures of the items',
            templateId: 'CustomerAttachment0002',
            requiredInd: false,
          },
        ],
      };

      // update payload
      payload.policyChangeSection = [policyChangeSection];

      // call submitPolicyChange
      props.dispatch(submitPolicyChange(payload));
    }
  };

  const history = useHistory();

  return (
    <>
      <NewPropertyCoverageContainer>
        <NewPropertyCoverageField>
          <NewPropertyCoverageFieldHeader>
            Requested Effective Date <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.newPropertyCoverage.effectiveDateTooltip')}
            />
          </NewPropertyCoverageFieldHeader>
          <input
            type="date"
            value={effectiveDate}
            aria-label="Requested Effective Date"
            min={todayDt}
            onChange={e => {
              setEffectiveDate(e.target.value);
            }}
          />
        </NewPropertyCoverageField>
        <NewPropertyCoverageField>
          <NewPropertyCoverageFieldHeader>
            Description <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.newPropertyCoverage.descriptionTooltip')}
            />
          </NewPropertyCoverageFieldHeader>
          <NewPropertyCoverageTextAreaField
            value={description}
            onChange={e => {
              setDescription(e.target.value);
            }}
          />
        </NewPropertyCoverageField>
        <NewPropertyCoverageField>
          {uploadFiles.length > 0 && (
            <PicturesContainer>
              {uploadFiles.map((file, index) => (
                <ThumbnailContainer key={index}>
                  {useFallbackThumbnail(file) ? (
                    <FileThumbnailContainer>
                      <FileThumbnail />
                      <FileName>{file.name}</FileName>
                    </FileThumbnailContainer>
                  ) : (
                    <ImgThumbnail src={file.url} />
                  )}
                  <GarbageIconContainer>
                    <GarbageIcon
                      onClick={() => {
                        uploadFiles.splice(index, 1);
                        setUploadFiles(uploadFiles => [...uploadFiles]);
                      }}
                    />
                  </GarbageIconContainer>
                </ThumbnailContainer>
              ))}
            </PicturesContainer>
          )}
        </NewPropertyCoverageField>
        <NewPropertyCoverageField>
          <NewPropertyCoverageFieldHeader>
            Pictures of the items
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.newPropertyCoverage.picturesTooltip')}
            />
          </NewPropertyCoverageFieldHeader>
          <label>
            <NewPropertyCoverageFileUploadButton>
              <PitcureIcon className="fa fa-picture-o" /> Select File(s)
              <input
                type="file"
                id="uploadNewPropertyFile"
                multiple
                accept="image/*,video/*,.pdf,.txt,.docx"
                style={{
                  display: 'none',
                  width: '115px',
                }}
                onChange={() => {
                  handleFileSelection();
                }}
              />
            </NewPropertyCoverageFileUploadButton>
          </label>
        </NewPropertyCoverageField>
        <NewPropertyCoverageButtonsContainer>
          <NewPropertyCoverageButton
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            Cancel
          </NewPropertyCoverageButton>
          <NewPropertyCoverageButton
            id="Submit"
            disabled={!checkRequiredFields() || isSubmitting}
            onClick={() => {
              onSubmitButtonClicked();
            }}
          >
            Submit
          </NewPropertyCoverageButton>
          {isSubmitting && <Loader loaderheight="20px" loaderwidth="20px" />}
        </NewPropertyCoverageButtonsContainer>
      </NewPropertyCoverageContainer>
    </>
  );
};
RequestNewPropertyCoverage.propTypes = {
  dispatch: PropTypes.func,
  customerId: PropTypes.string,
  selectedPolicyChangeTemplate: PropTypes.object,
  selectedPolicy: PropTypes.object,
  uploadFileSize: PropTypes.string,
  i18n: PropTypes.object,
};

const mapStateToProps = state => ({
  customerId: state.customerSlice.customer.systemId,
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  uploadFileSize: state.configurationSlice.features.maxUploadFileSize,
  i18n: state.i18nSlice.i18n,
  state,
});

export default connect(mapStateToProps)(RequestNewPropertyCoverage);
