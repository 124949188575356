import styled from 'styled-components';

export const LoaderMargin = styled.div`
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
    margin-top: 16px;
  }
`;

export const PolicyDetailsContainer = styled.div.attrs({
  id: 'PolicyDetailsContainer',
})`
  margin: 30px;
`;

export const DocumentsHeader = styled.h3.attrs({
  id: 'PolicyDetailsDocumentsHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.525rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
    margin-top: 2%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-top: 2%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-top: 2%;
  }
  @media (min-width: 1280px) {
    margin-top: 2%;
  }
`;

export const PolicyDetailsHeader = styled.h3.attrs(props => ({
  id: `PolicyDetailsHeader${props.id}`,
}))`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.525rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
    margin-top: 2%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-top: 2%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-top: 2%;
  }
  @media (min-width: 1280px) {
    margin-top: 2%;
  }
`;

export const PolicyDocumentsLabelContainer = styled.div`
  display: grid;
  border-bottom: 2px solid #444;
  @media (max-width: 739px) {
    border-bottom: none;
  }
  @media (min-width: 739px) and (max-width: 979px) {
    grid-template-columns: 4fr 1fr 1fr;
    grid-template-rows: 4fr 1fr 1fr;
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 4fr 1fr 1fr;
    grid-template-rows: 4fr 1fr 1fr;
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 4fr 1fr 1fr;
    grid-template-rows: 4fr 1fr 1fr;
    margin: 16px 5%;
  }
`;
export const PolicyFormsDataContainer = styled.div`
  display: grid;
  word-break: break-word;
  @media (max-width: 739px) {
    row-gap: 16px;
    margin: 16px 0 16px 0;
    padding: 0 0 16px 0;
    border-bottom: 2px solid #ddd;
    grid-template-rows: 1fr 1fr;
  }
  @media (min-width: 739px) and (max-width: 979px) {
    grid-template-columns: 2fr 1fr;
    margin: 1% 3% 3% 3%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 2fr 1fr;
    margin: 2% 3% 4% 3%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 2fr 1fr;
    margin: 30px 5%;
  }
`;
export const PolicyFormsLabelContainer = styled.div`
  display: grid;
  border-bottom: 2px solid #444;
  @media (max-width: 739px) {
    border-bottom: none;
  }
  @media (min-width: 739px) and (max-width: 979px) {
    grid-template-columns: 1fr 2fr 1fr 1fr;
    margin-left: 3%;
    margin-right: 3%;
    padding-bottom: 2%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 1fr 2fr 1fr 1fr;
    margin: 16px 3%;
    padding-bottom: 2%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr 2fr 1fr 1fr;
    margin: 16px 5%;
    padding-bottom: 2%;
  }
`;

export const policyDetailsInformationLabel = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;

export const DocumentTypeLabel = styled(policyDetailsInformationLabel).attrs(props => ({
  id: `documentTypeLabel${props.id}`,
}))`
  @media (max-width: 739px) {
    display: none;
  }
`;
export const DownloadLinkLabel = styled(policyDetailsInformationLabel).attrs({
  id: 'downloadLinkLabel',
})`
  @media (max-width: 739px) {
    display: none;
  }
`;

export const DocumentTypeLabelMobile = styled(policyDetailsInformationLabel).attrs({
  id: 'deliveryPreferenceLabel',
})`
  @media (min-width: 740px) {
    display: none;
  }
`;

export const DownloadLinkLabelMobile = styled(policyDetailsInformationLabel).attrs({
  id: 'deliveryPreferenceLabel',
})`
  @media (min-width: 740px) {
    display: none;
  }
`;

export const policyDetailsDataDiv = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;

export const DocumentTypeContainer = styled(policyDetailsDataDiv).attrs({
  id: 'DocumentTypeContainer',
})`
  @media (min-width: 600px) {
  }
`;

export const DownloadLinkContainer = styled(policyDetailsDataDiv).attrs({
  id: 'DownloadLinkContainer',
})`
  @media (min-width: 600px) {
  }
`;

export const PolicyDocumentsDataContainer = styled.div`
  display: grid;
  word-break: break-word;
  @media (max-width: 739px) {
    row-gap: 16px;
    margin: 16px 0 16px 0;
    padding: 0 0 16px 0;
    border-bottom: 2px solid #ddd;
    grid-template-rows: 1fr 1fr 1fr;
  }
  @media (min-width: 739px) and (max-width: 979px) {
    grid-template-columns: 4fr 1fr 1fr;
    grid-template-rows: 4fr 1fr 1fr;
    margin: 1% 3% 1% 3%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 4fr 1fr 1fr;
    grid-template-rows: 4fr 1fr 1fr;
    margin: 2% 3% 2% 3%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 4fr 1fr 1fr;
    grid-template-rows: 4fr 1fr 1fr;
    margin: 16px 5%;
  }
`;

export const GoPaperlessPolicyPreferenceContainer = styled.div`
  display: grid;
  word-break: break-word;
  @media (max-width: 739px) {
    margin: 16px 0 16px 0;
    padding: 0 0 16px 0;
    border-bottom: 2px solid #ddd;
    grid-template-rows: 1fr 1fr 1fr;
  }
  @media (min-width: 739px) and (max-width: 979px) {
    grid-template-columns: 3fr 1fr 1fr;
    grid-template-rows: 3fr 1fr 1fr;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 3fr 1fr 1fr;
    grid-template-rows: 3fr 1fr 1fr;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 3fr 1fr 1fr;
    grid-template-rows: 3fr 1fr 1fr;
  }
`;
export const PreferenceRadioEmail = styled(policyDetailsDataDiv).attrs({
  id: 'radioPreferenceContainer',
})`
  @media (max-width: 599px) {
    margin: 0px 0px 8px 0px;
  }
  margin: 0px 0px 15px 0px;
`;
export const PreferenceRadioMail = styled(policyDetailsDataDiv).attrs({
  id: 'mailPreferenceContainer',
})`
  @media (max-width: 599px) {
    margin: 0px 0px 30px 0px;
  }
  margin: 0px 15px 15px 0px;
`;
export const PdfIcon = styled.i.attrs({
  className: 'fa fa-file-pdf-o',
})`
  margin: 0 1% 0 0;
`;
PdfIcon.displayName = 'PdfIcon';

export const FileIcon = styled.i.attrs({
  className: 'fa fa-files-o',
})`
  margin: 0 1% 0 0;
`;
FileIcon.displayName = 'FileIcon';

export const InsetPanel = styled.div.attrs({
  className: 'inset panel-bg table-responsive p',
})``;
InsetPanel.displayName = 'InsetPanel';

export const PolicyInfoContainer = styled.div.attrs({})`
  border: 2px solid #444;
  padding: 15px;
`;

export const PolicyDocumentsContainer = styled.div.attrs({})`
  border: 2px solid #444;
  padding: 15px;
`;
PolicyDocumentsContainer.displayName = 'PolicyDocumentsContainer';

export const CustomContent = styled.div.attrs({
  className: 'custom-content',
})`
  text-align: left;
  word-break: break-word;
`;
CustomContent.displayName = 'CustomContent';

export const MapMarker = styled.i.attrs({
  className: 'fa fa-map-marker',
})`
  margin: 0 1% 0 0;
`;
MapMarker.displayName = 'MapMarker';

export const PolicyDetailsHelperText = styled.div.attrs({
  id: 'policyDetailsHelperText',
})`
  margin: 16px 0 0 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
`;

export const PolicyAttachmentsDataContainer = styled.div`
  display: grid;
  word-break: break-word;
  @media (max-width: 739px) {
    row-gap: 16px;
    margin: 16px 0 16px 0;
    padding: 0 0 16px 0;
    border-bottom: 2px solid #ddd;
  }
  @media (min-width: 739px) and (max-width: 979px) {
    grid-template-columns: 2fr 2fr 1fr 1fr;
    margin: 1% 3% 1% 3%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 2fr 2fr 1fr 1fr;
    margin: 2% 3% 2% 3%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 5fr 7fr 3fr 3fr;
    margin: 20px 5%;
  }
`;

export const PolicyAttachmentsLabelContainer = styled.div`
  display: grid;
  border-bottom: 2px solid #444;
  @media (max-width: 739px) {
    border-bottom: none;
  }
  @media (min-width: 739px) and (max-width: 979px) {
    grid-template-columns: 2fr 2fr 1fr 1fr;
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 2fr 2fr 1fr 1fr;
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 5fr 7fr 3fr 3fr;
    margin: 16px 5%;
    padding: 10px 0;
  }
`;
export const PolicyAttachmentsUploadButtonContainer = styled.div`
  @media (min-width: 739px) and (max-width: 979px) {
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 1280px) {
    margin: 16px 5%;
    padding: 10px 0;
  }
`;
