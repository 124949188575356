import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const MFAEditContainer = styled.div``;
export const MFATitle = styled.h4`
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  font-weight: 500;
`;

export const MFAContainer = styled.div.attrs({
  id: 'MfaContainer',
})`
  padding: 16px 30px 16px 30px;
  margin-top: 30px;
  @supports (-moz-appearance: none) {
    margin-bottom: 30px;
  }
  border-bottom: 2px solid ${(props) => props.theme.gray};
  border-top: 2px solid ${(props) => props.theme.gray};
`;

export const MFAEnrollContainer = styled.div.attrs({
  id: 'mfaInputAndText',
})`
  padding: 0;
`;
export const ProfileLabel = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 8px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;
export const MFALabel = styled(ProfileLabel).attrs({
  id: 'mfaLabel',
})``;
export const HelpIcon = styled.i.attrs({
  className: 'fa fa-info-circle',
})`
  margin-left: 6px;
  color: #777;
  font-size: 16px;
`;
export const PhoneSelect = styled.input.attrs({
  type: 'checkbox',
  id: 'phoneSelect',
})`
  margin-right: 4px;
`;
export const EmailSelect = styled.input.attrs({
  type: 'checkbox',
  id: 'emailSelect',
})`
  margin-right: 4px;
`;
export const PhoneContainer = styled.div.attrs({
  id: 'phoneRadioContainer',
})`
  margin-top: 16px;
`;
export const EmailContainer = styled.div.attrs({
  id: 'emailRadioContainer',
})`
  margin-top: 16px;
`;

export const ConfirmMFACode = styled.button.attrs({
  id: 'confirmMFACode',
})`
  margin-top: 16px;
  border: none;
  padding: 12px;
  width: 102%;
  background-color: ${(props) => (props.theme.primaryColor ? props.theme.primaryColor : 'blue')};
  color: ${(props) => (props.theme.primaryColorText ? props.theme.primaryColorText : 'white')};
  :disabled {
    background-color: gray;
  }
`;
export const ConfirmPhone = styled.button.attrs({
  id: 'confirmPhoneNumberForMFA',
})`
  margin-top: 16px;
  border: none;
  padding: 12px;
  width: 102%;
  background-color: ${(props) => (props.theme.primaryColor ? props.theme.primaryColor : 'blue')};
  color: ${(props) => (props.theme.primaryColorText ? props.theme.primaryColorText : 'white')};
  :disabled {
    background-color: gray;
  }
`;
export const ResendMFACode = styled.button.attrs({
  id: 'resendMFACode',
})`
  margin-top: 16px;
  border: none;
  padding: 12px;
  width: 102%;
  /* background-color: ${(props) =>
    props.theme.primaryColor ? props.theme.primaryColor : 'blue'};*/
  color: ${(props) => (props.theme.primaryColorText ? props.theme.primaryColorText : 'white')};
  :disabled {
    background-color: gray;
  }
`;
export const ModalContainer = styled.div`
  /* 320 x 304 */
  width: 460px;
  height: 480px;
  padding: 24px;
  @media (max-width: 599px) {
    width: 80%;
  }
`;
export const ModalHeader = styled.h3``;
export const ModalNote = styled.div`
  font-weight: 600;
  margin-top: 16px;
`;
export const CancelMFA = styled.button`
  padding: 12px;
  border: none;
  margin-top: 16px;
  width: 102%;
  color: white;
`;
export const ConfirmCodeInput = styled.input`
  margin-top: 16px;
  width: 100%;
  border: 1px solid black;
  border-radius: 2px;
  :hover {
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  }
  outline: 0;
`;
export const PhoneInput = styled(InputMask).attrs({
  id: 'phoneForMFAInput',
})`
  margin-top: 16px;
  width: 100%;
  border: 1px solid black;
  border-radius: 2px;
  :hover {
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  }
  outline: 0;
`;
export const StyledLabel = styled.label`
  :hover {
    cursor: pointer;
  }
`;
export const EmailStyledLabel = styled.label`
  :hover {
    cursor: pointer;
  }
  color: gray;
`;
export const StyledMfaUnEnrollModal = styled.div.attrs({
  id: 'MfaUnEnrollModalContainer',
})`
  padding: 16px;
`;
export const MfaUnEnrollInformation = styled.div.attrs({
  id: 'MfaUnEnrollDeletionInformation',
})`
  margin: 16px 0 0 16px;
  padding-right: 15px;
`;
export const CancelMfaUnEnrollButton = styled.button.attrs({
  id: 'cancelMfaUnEnrollButton',
})`
  padding: 8px 10px 10px 10px;
  margin: 0 8px 0 0;
`;
export const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 16px;
`;
export const MfaUnEnrollButton = styled.button.attrs({
  id: 'MfaUnEnrollButton',
})`
  background-color: #af0000;
  color: white;
  padding: 10px;
  margin: 25px 5px 25px 0;
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px rgb(40 51 63 / 24%);
  :hover {
    background-color: #d90000;
  }
  :active {
    background-color: #ba0000;
  }
`;
export const StyledMfaUnEnrollHeader = styled.h4.attrs({
  id: 'MfaUnEnrollHeader',
})`
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  font-family: proxima-nova, sans-serif;
`;

