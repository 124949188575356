import styled from 'styled-components';
export const IconSpan = styled.span.attrs({})`
  position: absolute;
  margin-top: 14px;
  margin-left: -22px;
  @media (max-width: 599px) {
    position: relative;
    bottom: 25px;
    left: 100%;
  }
`;
export const DateIconSpan = styled.span.attrs({})`
  position: absolute;
  margin-top: 14px;
  margin-left: -42px;
`;
