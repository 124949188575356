import styled from 'styled-components';
export const Button = styled.button.attrs({
  id: 'buttonContainer',
})`
  padding: 10px 15px;
  border: 0;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
  @media (max-width: 599px) {
    margin-top: 10px;
  }
  @media (min-width: 739px) and (max-width: 979px) {
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 1280px) {
    margin: 8px 5% 8px 0;
  }
  :disabled {
    background-color: gray;
  }
`;
export const ModalCloserSpaced = styled.a`
  padding: 10px 20px 10px 0px;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
  font-size: 21px;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  float: right;
  text-align: right;
`;

export const FileUploadContentContainer = styled.div.attrs({
  id: 'fileUploadContainer',
})`
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
  }
`;
export const FileUploadHeaderContainer = styled.div.attrs({
  id: 'fileUploadHeaderContainer',
})`
  display: grid;

  @media (max-width: 599px) {
    grid-template-columns: 100%;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 50% 50%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 50% 50%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 50% 50%;
  }
`;
export const FileUploadHeader = styled.h3.attrs({
  id: 'fileUploadHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.525rem;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-top: -10px;
`;
export const FileUploadSubHeader = styled.h3.attrs({
  id: 'PolicyDetailsDocumentsHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-top: 3%;
  margin-bottom: 10px;
`;
export const FileUploadFormContainer = styled.div.attrs({
  id: 'fileUploadFormContainer',
})`
  display: grid;
  margin-right: 3%;
  @media (max-width: 599px) {
    margin-bottom: 16px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;
export const FileUploadFormFieldHeader = styled.h4.attrs({
  id: 'fileUploadSelectionHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 12px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const HalfWidthFieldContainer = styled.div.attrs({
  id: 'halfWidthFieldContainer',
})`
  display: grid;
  grid-template-columns: 50% 50%;
  @media (max-width: 599px) {
    grid-template-columns: 100%;
  }
`;
export const DescriptionTextInputField = styled.input.attrs({
  type: 'text',
})`
  height: 30px;
  margin-right: 5%;
`;
export const FileUploadButton = styled.div.attrs({
  id: 'fileUploadButton',
})`
  padding: 8px 15px;
  width: 110px;
  height: 18px;
  display: inline-block;
  margin-top: 16px;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  :hover {
    background-color: ${props =>
      props.theme.primaryHoverColor
        ? props.theme.primaryHoverColor
        : props.theme.primaryColor
        ? props.theme.primaryColor
        : 'gray'};
  }
  :active {
    background-color: ${props => props.theme.primaryActiveColor};
  }
  @media (max-width: 384px) {
    margin-bottom: 5px;
  }
`;

export const PitcureIcon = styled.i.attrs({
  id: 'pictureIcon',
})`
  @media (max-width: 299px) {
  }
`;
export const PicturesContainer = styled.div.attrs({})`
  border: 2px solid ${props => props.theme.secondaryColor};
  padding: 10px 0 5px 10px;
`;
export const ThumbnailContainer = styled.div.attrs({})`
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
`;
export const FileThumbnailContainer = styled.div.attrs({})`
  display: inline-block;
  margin-right: 20px;
`;
export const GarbageIconContainer = styled.div.attrs({})`
  margin-left: 15px;
`;
export const GarbageIcon = styled.i.attrs({
  className: 'fa fa-trash-o',
})`
  cursor: pointer;
  margin-left: -15px;
`;
export const FileName = styled.span`
  word-break: break-word;
  overflow: hidden;
  height: 40px;
  display: inline-block;
  max-width: 120px;
`;
export const FileThumbnail = styled.i.attrs({
  className: 'fa fa-file',
})`
  font-size: 80px;
  display: block;
`;
export const ImgThumbnail = styled.img.attrs({})`
  width: 120px;
  height: 120px;
`;
export const ButtonsContainer = styled.div.attrs({})`
  text-align: right;
  margin: 4% 2%;
`;
export const CancelButton = styled.button.attrs({
  id: 'Cancel',
})`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
`;
export const SaveButton = styled.button.attrs({})`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  :disabled {
    background-color: gray;
  }
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
`;
export const RequiredField = styled.span.attrs({})`
  color: red;
`;
export const FileUploadButtonLabel = styled.label.attrs({})`
  width: 140px;
`;
export const StyledLoaderContainer = styled.div.attrs({})`
  position: relative;
  display: inline-block;
  top: 4px;
  left: 4px;
  margin-right: 4px;
`;
