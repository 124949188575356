import styled from 'styled-components';
export const HelpIconLarge = styled.i.attrs({
  className: 'fa fa-info-circle',
})`
  margin-left: 6px;
  color: #777;
  font-size: 16px;
`;

export const HelpIconMedium = styled.i.attrs({
  className: 'fa fa-info-circle',
})`
  margin-left: 6px;
  color: #777;
  font-size: 12px;
`;
