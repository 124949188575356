import styled from 'styled-components';

export const MyAgentHeader = styled.h3.attrs({
  id: `agentHeader`,
})`
  font-size: 14px;
  color: black;
  margin-bottom: 6px;
`;

export const MyAgentContainer = styled.div.attrs(props => ({
  id: `agentPanel${props.id}`,
}))`
      margin: 16px;
  /* ${props => (props.template === 'template2' ? `border-bottom: 2px solid black` : null)}; */
`;
export const MyAgentAddress = styled.div.attrs({
  id: 'agentsAddress',
})``;
export const AddressLineOne = styled.div.attrs({})`
  color: black;
`;
export const AddressLineTwo = styled.div.attrs({})`
  color: black;
`;
export const TemplateOnePanel = styled.h2.attrs({
  id: 'templateOnePanel',
})`
  background-color: ${props => props.theme.primaryColor};
  color: white;
  text-align: center;
  padding-top: 16px;
  margin: 0;
  font-weight: 400;
  padding-bottom: 16px;
  font-size: 24px;
`;
export const AgentContainer = styled.div.attrs({
  id: 'outshellAgentContainer',
})`
  @media (min-width: 900px) and (max-width: 1279px) {
    margin: 2%;
  }
  ${props =>
    props.template === 'template1'
      ? `border: 3px solid ${props.theme.primaryColor}`
      : `border: none`};
  margin: ${props => (props.template === 'template1' ? `6%` : `15px`)};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

export const PoliciesSubHeader = styled.h4.attrs({})`
  margin-bottom: 0;
`;
export const AgentsEmailHeader = styled.h4.attrs({
  id: 'agentEmailHeader',
})`
  color: black;
  font-size: 14px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 12px;
`;
export const AgentsPhoneHeader = styled.h4.attrs({
  id: 'agentsPhoneHeader',
})`
  color: black;
  font-size: 14px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 12px;
`;
