import styled from 'styled-components';
import Loader from '../../common/loader';

export const LoaderMargin = styled.div`
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
    margin-top: 16px;
  }
`;
export const IdCardsContainer = styled.div.attrs({
  id: 'IdCardsContainer',
})`
  margin: 30px;
`;

export const StyledLoader = styled(Loader).attrs({
  id: 'MailIdLoader',
})``;

export const DigitalIdCardHeader = styled.h1.attrs({
  id: 'DigitalIdCardHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.525rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-top: 0;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const IdCardsPrintLabelContainer = styled.div`
  display: grid;
  border-bottom: 2px solid #444;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 4fr 1fr;
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 4fr 1fr;
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 4fr 1fr;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const idCardsInformationLabel = styled.h2.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;

export const DescriptionLabel = styled(idCardsInformationLabel).attrs({
  id: 'documentTypeLabel',
})``;

export const DownloadLinkLabel = styled(idCardsInformationLabel).attrs({
  id: 'downloadLinkLabel',
})``;

export const idCardsDataDiv = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;

export const IdCardsDescriptionContainer = styled(idCardsDataDiv).attrs({
  id: 'DocumentTypeContainer',
})`
  @media (min-width: 600px) {
  }
`;

export const DownloadLinkContainer = styled(idCardsDataDiv).attrs({
  id: 'DownloadLinkContainer',
})`
  @media (min-width: 600px) {
  }
`;

export const IdCardsDocumentsDataContainer = styled.div`
  display: grid;
  word-break: break-word;
  @media (max-width: 599px) {
    row-gap: 16px;
    margin: 16px 0 16px 0;
    padding: 0 0 16px 0;
    border-bottom: 2px solid #ddd;
    grid-template-rows: 1fr 1fr;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 4fr 1fr;
    margin: 1% 3% 1% 3%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 4fr 1fr;
    margin: 2% 3% 2% 3%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 4fr 1fr;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const PdfIcon = styled.i.attrs({
  className: 'fa fa-file-pdf-o',
})`
  margin: 0 1% 0 0%;
`;
PdfIcon.displayName = 'PdfIcon';

export const FileIcon = styled.i.attrs({
  className: 'fa fa-files-o',
})`
  margin: 0 1% 0 0%;
`;
FileIcon.displayName = 'FileIcon';

export const InsetPanel = styled.div.attrs({
  className: 'inset panel-bg table-responsive p',
})``;
InsetPanel.displayName = 'InsetPanel';

export const IdCardsDocumentsContainer = styled.div.attrs({})`
  border: 2px solid #444;
  padding: 15px;
`;
IdCardsDocumentsContainer.displayName = 'IdCardsDocumentsContainer';

const Button = styled.button.attrs({
  type: 'button',
})`
  border: none;
  font-size: 14px;
  margin: 5px;
  text-align: center;
`;

export const MailIdsButton = styled(Button).attrs({
  id: 'mailIdsButton',
})`
  background-color: ${props => props.theme.primaryColor};
  color: white;
  padding: 10px;
`;
